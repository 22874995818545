import React from 'react';
import './component-css/Footer.css'; 

const Footer = () => {
return (
<footer className="footer">
    <p>&copy; 2024 1FUTURE. All Rights Reserved.</p>
    <div className="social-icons">
        <a href="https://www.linkedin.com/posts/1future_1future-linkedin-activity-7055539420581507072-oyYL/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i> 
        </a>
    </div>
</footer>
);
};

export default Footer;
