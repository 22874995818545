import React, { useState } from 'react';
import './component-css/Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
const [isDropdownOpen, setIsDropdownOpen] = useState(false);

const handleAboutUsClick = (e) => {
e.preventDefault();
window.open('/aboutus', '_blank');
};

const toggleDropdown = () => {
setIsDropdownOpen(!isDropdownOpen);
};

return (
<nav className="navbar">
    <div className="left-items">
    <img src="/images/logo1.png" alt="Logo" className="logo-image" />
    </div>
    <ul className="navbar-nav">
    <li className="nav-item">
        <a href="/" className="nav-link" onClick={handleAboutUsClick}>About Us</a>
    </li>
    <li className="nav-item">
        <a href="https://www.1future-platform.com/" className="nav-link">Forum</a>
    </li>
    <li className="nav-item">
        <a href="https://1future.feut.edu.al/" target="_blank" className="nav-link">Website</a>
    </li>
    
    {/* Learning Materials Dropdown */}
    <li className="nav-item dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
        <span className="nav-link dropdown-toggle">Learning Materials</span>
        {isDropdownOpen && (
        <ul className="dropdown-menu">
            <li><a href="/learning-materials/risk-management" className="dropdown-item">Risk Management</a></li>
            <li><a href="/learning-materials/energy-efficiency" className="dropdown-item">Energy Efficiency</a></li>
            <li><a href="/learning-materials/urban-resilience" className="dropdown-item">Urban Resilience</a></li>
            <li><a href="/learning-materials/sustainable-construction" className="dropdown-item">Sustainable Construction</a></li>
            <li><a href="/learning-materials/economics-management" className="dropdown-item">Economics and Management</a></li>
        </ul>
        )}
    </li>

    {/* <li className="nav-item">
        <a href="#here" className="nav-link">Resources</a>
    </li> */}
    <li className="nav-item dropdown">
        <a 
            href="#here" 
            className="nav-link dropdown-toggle" 
            id="resourcesDropdown" 
            role="button" 
            data-bs-toggle="dropdown" 
            aria-expanded="false"
        >
            Resources
        </a>
        <ul className="dropdown-menu" aria-labelledby="resourcesDropdown">
            <li>
            <a 
                className="dropdown-item" 
                href="/videos/Tutorial1.mp4" 
                target="_blank" 
                rel="noopener noreferrer"
            >
                Tutorial 1
            </a>
            </li>
            <li>
            <a 
                className="dropdown-item" 
                href="/videos/Tutorial2.mp4" 
                target="_blank" 
                rel="noopener noreferrer"
            >
                Tutorial 2
            </a>
            </li>
        </ul>
        </li>
    </ul>
</nav>
);
};

export default Navbar;
