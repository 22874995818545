import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './components/AboutUs'; 

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        {/* Render the image only on the main page */}
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <img src="/images/background.png" alt="Background" className="background-image" />
                {/* Add more main page content here if needed */}
              </>
            } 
          />
          <Route path="/aboutus" element={<AboutUs />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
